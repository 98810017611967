import * as React from "react"
import Spacer from "../components/spacer"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutUs = ({ content, image, className }) => (
  <div className="about--us container ma p20 pb0">
    <div className="max-650 ma">
      {( image ?
        <>
        <div className="ratio-2-1 bg-grey pos-rel">
          <GatsbyImage className="bg-image" image={image?.gatsbyImageData} alt="About Image" />
        </div>
        <Spacer className='small' />
        </> : ''
      )}
      <div className={'large white-space journal fade--in '+className} data-sal data-sal-delay='300' dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </div>
)

export default AboutUs
