import * as React from "react"

const TextBlock = ({ content, signature }) => (
  <div className="text-block p20 container ma fade--in" data-sal data-sal-delay='300'>
    <div className="max-850 h1 text-center ma">{content}</div>
    {( signature ?
      <div className='mt40 ma' style={{maxWidth:'150px'}}>
        <div className='signature' data-sal>
          <video playsInline={true} muted={true} className='signature-video w-100'>
            <source src="https://player.vimeo.com/progressive_redirect/playback/750246833/rendition/240p/file.mp4?loc=external&signature=b7657d4c5d385882ca66b4ee11aff4c2c739ffeb31b5974eab24a464fc7cd56e"/>
          </video>
        </div>
      </div>
      :''
    )}
  </div>
)

export default TextBlock
