import * as React from "react"

const Details = ({details, className}) => {

	return (
		<div className="conpany--details medium p20 container ma fade--in" data-sal data-sal-delay='300'>
			<div className={'max-650 ma '+className}>
			{details.map((detail, index) => { 
              return (
                <div key={index} className={( index!== details.length-1 ? 'mb40' : '')}>
                  <p className='m0 mb10'>{detail.title}</p>
                  <div className='pl20 grey'>
                  	{detail.content}
                  </div>
                </div>
              )
            })}
            </div>
		</div>
	)	
}

export default Details
