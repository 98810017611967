import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const MapSection = ({image, title, content}) =>  {
	return (
		<div className='map-section ma container'>
			<div className='max-650 ma'>
				<p className='m0 journal large pb40 fade--in m-pl10 m-pr10' data-sal data-sal-delay='300'>{title}</p>
				<GatsbyImage className="image mt40" image={image.gatsbyImageData} alt="Map" />
			</div>
		</div>
	)
}

export default MapSection
