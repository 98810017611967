import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const HeroBanner = ({ title, image, video, autoplay, className=''}) => (
  <div id='hero' className={"hero--banner flex light "+className}>
    <h1 className="ma z-2 pos-rel hero--title text-center max-750 m-p20 menu-hide m-t40">
      {title}
    </h1>
    <GatsbyImage className="bg-image filter-br hero--image" image={image.gatsbyImageData} alt="Hero Image" />
    {( video ?
      <video loop={true} playsInline={true} muted={true} autoPlay={autoplay} className='bg-image filter-br hero--video'>
        <source src={video}/>  
      </video>
      : ''
    )}
  </div>
)

export default HeroBanner
