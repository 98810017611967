import React, { useEffect } from 'react';
import scrollTo from "./scrollTo"
import { Link } from "gatsby"

const Footer = ({className='', hideTop}) => {
	useEffect(() => {
		if(window.innerWidth > 768) {
			return;
		}
		var height =  window.innerHeight;
		window.addEventListener('scroll',onScroll)
		function onScroll() {
			if(window.pageYOffset > height) {
				document.querySelector('.back--top').classList.add('active');
			} else {
				document.querySelector('.back--top').classList.remove('active');
			}
		}
	},[]);
	return(
		<>
		<div className={'footer pos-rel p20 t-small fade--in '+className} data-sal data-sal-delay='300'>
			<div className='flex mb80'>
				<div className='m-hide' style={{width:'260px'}}>
					<Link className="link ul ul-link" to="/">Home</Link><br/>
					<Link className="link ul ul-link" to="/about">About</Link><br/>
					<Link className="link ul ul-link" to="/projects">Projects</Link>
				</div>
				<div className='w-100 max-650 mla mra'>
					The Pursuit of Smarter Living.
				</div>
				<div style={{width:'260px'}} />
			</div>
			<div className='flex m-wrap'>
				<div className='m-100' style={{width:'260px'}}>© TRK <span className='tablet-hide'>Property Group</span> {new Date().getFullYear()}</div>
				<div className='w-100 max-650 mla mra m-hide'>
				    <p className='m0 cursor-pointer' role='presentation' onClick={() => scrollTo('#hero')}>Back to top</p>	
				</div> 
				<div className='site-by m-100' style={{width:'260px'}}>
					Designed <span className='tablet-hide'>and developed</span> by <a href='https://groundcrew.com.au' target='_blank' rel="noreferrer" className='ul-link link'>Groundcrew</a>
				</div>
			</div> 
		</div>
		<div className={'back--top '+(hideTop ? 'hide':'')} role='presentation' onClick={() => scrollTo('#hero')}>Top</div>
		</>
	)
}

export default Footer
