import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Director = ({ content, image, mobileImage, className }) => (
  <>
    <div className='ratio-1-2 m-ratio-2-1 bg-grey pos-rel'>
      <GatsbyImage className="bg-image m-hide" image={image.gatsbyImageData} alt="Director Image" />
      <GatsbyImage className="bg-image m-show" image={mobileImage.gatsbyImageData} alt="Director Image" />
    </div>
    <div className={"text-block p20 mt40 container ma "}>
      <div className={"max-650 ma medium white-space "+className} dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  </>
)

export default Director
