import React, { useEffect } from 'react';
import Layout from "../components/layout"
import Footer from "../components/footer"
import HeroBanner from "../components/heroBanner"
import ScrollNavigation from "../components/scrollNavigation"
import TextBlock from "../components/textBlock"
import AboutUs from "../components/aboutUs"
import Details from "../components/detail"
import Director from "../components/director"
import Contact from "../components/contact"
import Spacer from "../components/spacer"
import Seo from "../components/seo"
import Map from "../components/map"
import { graphql } from "gatsby"
import {pageTransition} from '../utils/panelActions'



const AboutPage = ({ data, props }) => {
  useEffect(() => {
    pageTransition();
    const signature = document.querySelector('.signature');
    signature.addEventListener('sal:in', ({ detail }) => {
      document.querySelector('.signature-video').play();
    });
  },[]);
  data = data.datoCmsAboutPage
  return (
    <Layout>
      <HeroBanner title={data.heroTitle} image={data.heroImage} autoplay='true' video='https://player.vimeo.com/progressive_redirect/playback/751108457/rendition/1080p/file.mp4?loc=external&signature=633efe3896429a23936392f1a993c1e3ca6b69bd77d06422f4ee642f309bf294' />
      <div className='navigation--wrapper'>
        <ScrollNavigation />
        <div id='promise' className='promise--marker'/>
        <div>
          <Spacer />
          <TextBlock image={data.teamImage} content={data.aboutContent} signature={data.signature} />
        </div>
        <div id='about'>
          <Spacer />
          <AboutUs className='m-h3 m-pl10 m-pr10' content={data.teamContent} image={data.teamImage} />
        </div>
        <div id='values'>
          <Spacer />

          <div className='container ma'>
            <div className='max-650 ma text-center'>
              <div className='max-500 ma fade--in' data-sal>
                <video playsInline={true} autoPlay={true} loop={true} muted={true} className='signature-video w-100'>
                  <source src="https://player.vimeo.com/progressive_redirect/playback/755372462/rendition/720p/file.mp4?loc=external&signature=498f747dce7fab3447df98f063384581e85995556cc61d39f27185a17d2309af"/>
                </video>
              </div>
            </div>
          </div>


          <Spacer />
          <Details details={data.detail} className='m-pl10 m-pr10' />
        </div>
        <div id='team'>
          <Spacer />
          <Director className='m-pl10 m-pr10' content={data.directorContent} image={data.directorImage} mobileImage={data.mobileDirector} />
        </div>
        <div id='projects'>
          <Spacer />
          <Map title={data.mapTitle} image={data.mapImage} />
        </div>
        <div id='career'>  
          <Spacer />
          <Contact content={data.contact} />
          <Spacer />
          <Spacer />
        </div>
      </div>
      <Footer /> 
    </Layout>
  )
}

export const Head = () => <Seo title="Home" />

export default AboutPage

export const query = graphql`
  query AboutQueryTwo {
    datoCmsAboutPage {
      heroTitle
      heroImage {
        gatsbyImageData
      }
      aboutContent
      contact
      detail {
        title
        content
      }
      directorContent
      teamImage {
        gatsbyImageData
      }
      directorImage {
        gatsbyImageData
      }
      mobileDirector {
        gatsbyImageData
      }
      mapImage {
        gatsbyImageData
      }
      mapTitle
      signature {
        gatsbyImageData
      }
      teamContent
    }
  }
`
