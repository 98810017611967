import React, { useEffect } from 'react';
import scrollTo from "./scrollTo"

const ScrollNavigation = () => {
	useEffect(() => {
		window.addEventListener('scroll',onScroll)
		var height = window.innerHeight/2;
		function onScroll() {
		    var removeActiveClass = function (elements) {
		        for (var i = 0; i < elements.length; ++i) {
		            elements[i].classList.remove('active');
		        }
		    }
		    var anchors = document.querySelectorAll('.fixed-navigation span');
		    var previousRefElement = null;
		    for (var i = 0; i < anchors.length; ++i) {
		        var currentRefElement = document.getElementById(anchors[i].getAttribute('data-href').substring(1));
		        var currentRefElementTop = currentRefElement.getBoundingClientRect().top;
		        if (currentRefElementTop <= height) {
		            previousRefElement = anchors[i];
		            if (i === anchors.length - 1) {
		                removeActiveClass(anchors);
		                anchors[i].classList.add("active");
		            }
		        } else {
		            removeActiveClass(anchors);
		            previousRefElement?.classList.add("active");
		            break;
		        }
		        
		    }
		}
	},[]);
	return (
		<div className='fixed-navigation m-hide'>
           <div className='fixed'>
            <span className='link active' role='presentation' onClick={() => scrollTo('#promise')} data-href='#promise'>Our Promise</span><br/> 
            <span className='link' role='presentation' onClick={() => scrollTo('#about')} data-href='#about'>About Us</span><br/>
            <span className='link' role='presentation' onClick={() => scrollTo('#values')}   data-href='#values'>Our Values</span><br/>
            <span className='link' role='presentation' onClick={() => scrollTo('#team')} data-href='#team'>Team</span><br/>
            <span className='link' role='presentation' onClick={() => scrollTo('#projects')} data-href='#projects'>Past Projects</span><br/>
            <span className='link' role='presentation' onClick={() => scrollTo('#career')} data-href='#career'>Career</span> 
           </div>
        </div>
	)
}	

export default ScrollNavigation
